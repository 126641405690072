import React, {useEffect, useState} from 'react';
import {hasAuthParams, useAuth} from "react-oidc-context";
import {useDispatch} from 'react-redux';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Home} from "./pages/home";
import {RegisterMFA} from "./pages/register-mfa";
import {Admin} from "./pages/admin";
import {useAppSelector} from './app/index';
import {setUser} from './app/reducer';
import {AdminBasePath} from "./constants/admin";

function App() {
    const dispatch = useDispatch();
    const auth = useAuth();
    const hasTokens = useAppSelector((state) => state.app.hasWebAuthnCreds);
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        return <div>Signing you in...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    dispatch(setUser({userToken: auth.user?.id_token ?? ''}));

    if (!hasTokens) {
        return <RegisterMFA/>
    }

    return (
        <Router>
            <Routes>
                <Route path="/" Component={Home}/>
                <Route path="/register" Component={RegisterMFA}/>
                <Route path={AdminBasePath} Component={Admin}/>
            </Routes>
        </Router>
    );
}

export default App;
