import React, {useState} from "react";
import {Box, Cards, ExpandableSection, Header, SpaceBetween, TextFilter} from "@amzn/awsui-components-react/polaris";
import {GenerateToken} from "./generate-token";
import {BadgePhotoBaseUrl} from "../constants/admin";

const EmptyCards = () => {
    return (
        <Box margin={{vertical: "xs"}} textAlign="center" color="inherit">
            <SpaceBetween size="m">
                <b>No users found</b>
            </SpaceBetween>
        </Box>
    )
}

export const UserManagementCards = ({users}: UserCardsProps) => {
    const [userSearchFilter, setUserSearchFilter] = useState("");

    const filteredUsers = () => {
        if (users?.length && userSearchFilter.length) {
            return users.filter(
                (item: User) =>
                    item.displayName
                        .toLocaleLowerCase()
                        .indexOf(userSearchFilter.toLocaleLowerCase()) >= 0 ||
                    item.alias.toLocaleLowerCase().indexOf(userSearchFilter.toLocaleLowerCase()) >=
                    0
            );
        }
        return users;
    };

    return (
        <>
            <Cards
                header={<Header>Find a user</Header>}
                cardDefinition={{
                    header: (item: User) => (
                        <SpaceBetween direction="horizontal" alignItems={"center"} size="s">
                            <img src={`${BadgePhotoBaseUrl}?uid=${item.alias}`}
                                 alt="" style={{blockSize: "50px", borderRadius: "10%"}}/>
                            <SpaceBetween direction="vertical" size="xxxs">
                                <Box variant="h2">
                                    {item.displayName}
                                </Box>
                                <Box variant="h5">
                                    {item.alias}
                                </Box>
                            </SpaceBetween>
                        </SpaceBetween>
                    ),
                    sections: [
                        {
                            id: "info",
                            content: (item: User) => (
                                <Box variant="p">
                                    Registered keys: {item.registeredKeys}
                                </Box>
                            ),
                        },
                        {
                            id: "generate-token",
                            content: (item: User) => (
                                <ExpandableSection headerText="Generate registration token">
                                    <GenerateToken user={item}></GenerateToken>
                                </ExpandableSection>
                            ),
                        }
                    ]
                }}
                cardsPerRow={[
                    {cards: 1},
                    {minWidth: 800, cards: 2}
                ]}
                items={filteredUsers()}
                loadingText="Loading users"
                trackBy="alias"
                visibleSections={["info", "generate-token"]}
                empty={EmptyCards()}
                filter={
                    <TextFilter filteringText={userSearchFilter} filteringPlaceholder="Search users"
                                filteringAriaLabel="Filter users"
                                onChange={({detail}) =>
                                    setUserSearchFilter(detail.filteringText)
                                }/>
                }

                /*    TODO: add preferences and pagination*/
            />
        </>
    )
}