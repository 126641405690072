import { PayloadAction } from "@reduxjs/toolkit";
import { createReducer, createAction } from "@reduxjs/toolkit";

export interface SetUserData {
    userToken: string;
}

export const setUser = createAction<SetUserData>('login');

export interface AppState {
    userName?: string;
    hasWebAuthnCreds?: boolean;
}

export const reducer = createReducer<AppState>({}, (builder) =>
    builder
        .addCase(setUser, (state: AppState, action: PayloadAction<SetUserData>) => {
            const idToken = parseJwt(action.payload.userToken);
            state.hasWebAuthnCreds = !idToken.amr?.includes('pwd');
        }),
);


function parseJwt(token: string) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

