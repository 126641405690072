import { LoginResponse, PublicKey } from "../services/idp/idp.types";
import { Base64UrlUtil } from "./base64url-arraybuffer";

const base64url = new Base64UrlUtil();
/**
 * Converts PublicKeyCredential into serialised JSON
 * @param  {Object} pubKeyCred
 * @return {Object}            - JSON encoded publicKeyCredential
 */
export const publicKeyCredentialToJSON = (pubKeyCred: any): any => {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};

    for (let key in pubKeyCred) {
      (obj as any)[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj;
  }

  return pubKeyCred;
};

/**
 * Decodes arrayBuffer required fields.
 */
export const preformatMakeCredReq = (makeCredReq: PublicKey) => {
  const challenge = base64url.decode(makeCredReq.challenge);
  const userId = base64url.decode(makeCredReq.user.id);

  return {
    ...makeCredReq,
    challenge,
    user: {
      ...makeCredReq.user,
      id: userId,
    },
  };
};

/**
 * Decodes arrayBuffer required fields.
 */
export const preformatGetAssertReq = (getAssert: LoginResponse) => {
  let assertion = { ...getAssert };
  const challenge = base64url.decode(getAssert.challenge);

  let allowCredentials = [];
  for (let allowCred of assertion.allowCredentials) {
    const id = base64url.decode(allowCred.id);
    allowCredentials.push({ ...allowCred, id });
  }

  return {
    ...getAssert,
    challenge,
    allowCredentials
  };
};
