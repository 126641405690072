import React from 'react';
import {SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react/polaris';

// for now, while we have few pages with simple paths, we can use window.location.pathname (with trailing slashes
// removed, since /page and /page/ are the same) to set the current active ref; as we add more pages or more complex
// navigation item hrefs, consider exposing activeHref as a prop
const activeHref = window.location.pathname.replace(/\/+$/, '');

export const DefaultSideNavigation = () => {
    const defaultNavHeader = {text: 'Kuiper IdP', href: '/'};
    const defaultNavItems: SideNavigationProps['items'] = [
        {
            type: 'section',
            text: 'Key Management',
            items: [
                {type: 'link', text: 'Register security key', href: '/register'},
            ],
        }
    ]

    return <SideNavigation items={defaultNavItems} header={defaultNavHeader} activeHref={activeHref}/>;
}

export const AdminSideNavigation = () => {
    const adminPathPrefix = "/admin";
    const adminNavHeader = {text: 'Kuiper IdP Admin', href: adminPathPrefix};
    const adminNavItems: SideNavigationProps['items'] = [
        {
            type: 'link',
            text: 'User Management',
            href: adminPathPrefix
        },
        {
            type: 'link',
            text: 'Key Management',
            href: "#"
        }
    ];

    return <SideNavigation items={adminNavItems} header={adminNavHeader} activeHref={activeHref}/>;
}