import React from "react";
import {ContentLayout, Header} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {AdminSideNavigation} from "../components/side-navigation";
import {UserManagementCards} from "../components/user-management-cards";
import {useUsersQuery} from "../services/idp/idp";

export const Admin = () => {
    // TODO: update to query with actual user id once included in auth scope
    const {data} = useUsersQuery("1234-5678-9012-1234");

    return (
        <PageLayout sideNav={<AdminSideNavigation/>} loggedInUsername={""}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1" description="Search for and manage users">User Management</Header>}
                defaultPadding={true}
                maxContentWidth={1080}
                disableOverlap={false}
                data-testid="admin-page"
            >
                <UserManagementCards users={data}/>
            </ContentLayout>
        </PageLayout>
    );
}