import {Box, Button, CopyToClipboard, SpaceBetween, Modal} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useGenerateRegistrationTokenMutation} from "../services/idp/idp";

export const GenerateToken = ({user}: UserManagementSectionProps) => {
    const [generateOtp, {data}] = useGenerateRegistrationTokenMutation();
    const [token, setToken] = useState(""); // we need a separate state variable for the token so we can clear it after closing the modal

    useEffect(() => {
        if (data) {
            setToken(data.password);
        }
    }, [data]);

    const generateRegistrationTokenForUser = (item: User) => {
        generateOtp({alias: item?.alias});
    };

    const closeTokenModal = () => {
        setToken("");
    }

    return (
        <>
            <SpaceBetween size="xs">
                <Box variant="p">
                    Click the button below to generate a single-use registration token. Copy the generated token and
                    send it to {user?.displayName?.length ? user?.displayName : user?.alias} via an approved
                    communication channel.
                </Box>
                <Button
                    variant="primary"
                    onClick={() => generateRegistrationTokenForUser(user)}
                    data-testid={`generate-token-button-${user?.alias}`}
                >
                    Generate token
                </Button>
            </SpaceBetween>
            <Modal
                onDismiss={closeTokenModal}
                visible={!!token}
                header={`Registration token for ${user.displayName}`}
                data-testid="generated-token-modal"
            >
                {token &&
                    <SpaceBetween direction="horizontal" alignItems="center" size="l">
                        <p data-testid="generated-token">{token}</p>
                        <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="Token failed to copy"
                            copySuccessText="Token copied to clipboard"
                            textToCopy={token}
                        />
                    </SpaceBetween>
                }
            </Modal>
        </>
    )
}