import React, {useState} from "react";
import {AppLayout} from '@amzn/awsui-components-react/polaris';
import {DefaultSideNavigation} from "./side-navigation";
import TopNavigation from "./top-navigation";

export const PageLayout = (props: PageLayoutProps) => {
    const [navigationOpen, setNavigationOpen] = useState(props.sideNavOpen ?? true);

    return (
        <>
            <TopNavigation loggedInUsername={props.loggedInUsername}/>
            <AppLayout
                disableContentPaddings={true}
                content={props.children}
                navigation={props.sideNav ?? <DefaultSideNavigation/>}
                navigationOpen={navigationOpen}
                onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                toolsHide={true}
                headerVariant="high-contrast"
            />
        </>

    );
}