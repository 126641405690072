import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import {User, WebStorageStateStore} from "oidc-client-ts";
import { getAppConfig } from "./services/config";

const appConfig = getAppConfig();

const oidcConfig: AuthProviderProps = {
    ...appConfig.openId,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: (user: User | void): void => {
         window.history.replaceState({}, document.title, window.location.pathname)
    }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
