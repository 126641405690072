declare global {
  interface Window {
    config: AppConfig;
  }
}

interface AppConfig {
  idpUrl: string;
  openId: OpenIdConfig;
}

export interface OpenIdConfig {
  authority: string;
  client_id: string;
  redirect_uri: string;
  activeNavigator: string;
  response_type: string;
  scopes: string;
}

let loadedConfig: AppConfig;

export const DefaultOpenIdConfig: Partial<OpenIdConfig> = {
  activeNavigator: "signInSilent",
  response_type: "code",
  scopes: "open_id",
};

export const loadConfig = () => {
  loadedConfig = window.config;
  loadedConfig.openId = {
    ...DefaultOpenIdConfig,
    ...window.config.openId,
  };
};

export const getAppConfig = (): AppConfig => {
  if (!loadedConfig) {
    loadConfig();
  }

  return loadedConfig;
};

export const getConfig = (value: keyof AppConfig): any => {
  return window.config[value];
};
